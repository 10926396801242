<!--日报汇总页-->
<template>
  <!-- 路径 -->
  <div class="path">
    <span><router-link to="/"> 首页</router-link></span> 
    &nbsp; &gt; &nbsp;
    <span>交易日报</span>
  </div>
  <div class="card-box">
    <div class="flex-start mb_15">
      <div class="news_title1">交易日报汇总统计图</div>
      <div class="news_title2">&nbsp;</div>
    </div>
    
    <div ref="echart_ref" class="com-chart"></div>

    <div class="flex-start mb_15">
      <div class="news_title1">交易日报汇总表</div>
      <div class="news_title2">&nbsp;</div>
    </div>
    
    <div class="search-div">  
      商户名称：
      <el-input placeholder="请输入商户名称" prefix-icon="search" v-model="shopName" clearable class="width"></el-input>            
      日期：
      <el-date-picker
        v-model="dateValue"
        type="date"
        placeholder="选择日期"
        format="YYYY年MM月DD日"
        value-format="YYYY-MM-DD"             
      />
      经营品类：
      <el-input placeholder="请输入经营商品类别" prefix-icon="search" v-model="businessFormatName" clearable class="width"></el-input>
      <el-button type="primary" icon="search" @click="getSummaryData">查询</el-button>
      <el-button type="primary" icon="Download" @click="getExportData">导出</el-button>
    </div>
    <el-table v-loading="tableLoading" :data="listData" highlight-current-row stripe border
        style="width: 100%" header-row-class-name="header-row" :row-class-name="tableRowClassName">
      <!-- <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column type="index" label="序" width="43"></el-table-column> -->
      <el-table-column prop="shopName" label="商户名称" show-overflow-tooltip min-width="200" />
      <el-table-column prop="boothNo" label="摊位号" min-width="100" />
      <el-table-column prop="businessFormatName" label="品类" min-width="100" />
      <el-table-column prop="tradeDate" label="日期" min-width="150" />
      <el-table-column prop="purchaseAmount" label="采购金额" min-width="100" align="right" />
      <el-table-column prop="saleAmount" label="销售金额" min-width="100" align="right" />
      <el-table-column prop="receiptAmount" label="收款金额" min-width="100" align="right" />
      <el-table-column prop="receiptCount" label="收款笔数" min-width="100" />
    </el-table>
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="currentPage" :page-sizes="[10, 15, 20, 30, 40]"
        :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total='tableTotal'>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {dateFormate, formatDate} from '@/utils/datetime_utils.js'
import {formatCurrency} from '@/utils/chineseNum.js'
export default {
  data () {
    return {
      tableLoading: false,
      currentPage: 1,
      pagesize: 20,
      tableTotal: 0,
      receiptTotalCount: 0, // 收款笔数合计
      receiptTotalAmount: 0, // 收款金额合计
      saleTotalAmount: 0, // 销售金额
      purchaseTotalAmount: 0, // 采购金额合计
      listData: [],
      shopName: '', // 商户名称
      businessFormatName: '', // 商户经营类别
      // dateValue: ['2024-11-08', '2024-11-08'],
      dateValue: [],
      // startDate: '', // 交易开始日期
      // endDate: '', // 交易结束日期 
      // chartInstance: null,
      isFirst: true // 是否第一次访问(不是分页访问)
    }
  },
  mounted () {
    // 当前月第一天和最后一天
    var dt = dateFormate('yyyy-MM-dd')
    // dt = "2024-11-25"
    this.dateValue = dt
    this.initEchart()
    this.getSummaryData()
    window.addEventListener('resize', this.screenAdapter)
    this.screenAdapter()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    // pageSize 新每页条数改变时触发(每页下拉显示数据)
    handleSizeChange (size) {
      this.pagesize = size
      this.getListData()
    },
    // 新当前页改变时触发(点击第几页)
    handleCurrentChange (currentPage) {
      this.currentPage = currentPage
      this.getListData()
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex === 0) {
        return 'Summary-row'
      }
      return ''
    },
    getSummaries() {
      const sums = {
        shopName: '合计',
        receiptCount: this.receiptTotalCount,
        receiptAmount: this.receiptTotalAmount,
        saleAmount: this.saleTotalAmount,
        purchaseAmount: this.purchaseTotalAmount
      }
      this.listData.unshift(sums)      
    },
    // 日报汇总
    async getSummaryData () {
      var en = {
        position: 'merchantTrade_queryTradeDailySummary',
        paramMap: {
          orgId: '9ce5a4d757f9d6434008b1449d20a641',
          businessFormatName: this.businessFormatName,
          shopName: this.shopName,
          startDate: this.dateValue,
          endDate: this.dateValue
        }
      }
      const { data: res } = await this.$http.post(this.$api.querySql, en) 
      // console.log('Summary', res)
      if (res.state.code === 10000) {
        this.receiptTotalCount = formatCurrency(res.body[0].receiptTotalCount, 0)
        this.receiptTotalAmount = '¥'+ formatCurrency(res.body[0].receiptTotalAmount, 2)
        this.saleTotalAmount = '¥'+ formatCurrency(res.body[0].saleTotalAmount, 2)
        this.purchaseTotalAmount = '¥'+ formatCurrency(res.body[0].purchaseTotalAmount, 2)
        this.isFirst = true
        this.getListData()
      } else {
        this.$message.error(res.state.message)
      }
    },
    // 日报明细
    async getListData () {
      this.tableLoading = true
      var en = {
        position: 'merchantTrade_queryTradeDailyDetail',
        paramMap: {
          orgId: '9ce5a4d757f9d6434008b1449d20a641',
          businessFormatName: this.businessFormatName,
          shopName: this.shopName,
          startDate: this.dateValue,
          endDate: this.dateValue
        },
        pageable: {
            pageNumber: this.currentPage,
            pageSize: this.pagesize
        }
      }
      const { data: res } = await this.$http.post(this.$api.querySqlByPage, en) 
      // console.log('Detail', res)
      if (res.state.code === 10000) {
        this.tableTotal = res.body.totalCount
        if (this.isFirst) {
          this.isFirst = false
          this.getEchartData(res.body.list)
        }
        this.listData = res.body.list
        this.listData.forEach(p => {
          p.receiptCount = formatCurrency(p.receiptCount, 0)
          p.receiptAmount = '¥'+ formatCurrency(p.receiptAmount, 2)
          p.saleAmount = '¥'+ formatCurrency(p.saleAmount, 2)
          p.purchaseAmount = '¥'+ formatCurrency(p.purchaseAmount, 2)
        })
        this.getSummaries()            
      } else {
        this.$message.error(res.state.message)
      }
      this.tableLoading = false
    },
    async getExportData () {
      var dt = formatDate(this.dateValue, 'yyyy-MM-dd')
      var exportFileName = `交易日报汇总表${dt}`
      var en = {
        "sheetDatas":
          {
            "fields": [
              "shopName",
              "boothNo",
              "businessFormatName",              
              "tradeDate",
              "purchaseAmount",
              "saleAmount",
              "receiptAmount",
              "receiptCount"
            ],
            "titles": [
              "商户名称",
              "摊位号",
              "品类",
              "日期",
              "采购金额（元）",
              "销售金额（元）",
              "收款金额（元）",
              "收款笔数"
            ],
            "sheetName": '交易日报汇总表'
          },        
        "param": {
            "position": "merchantTrade_queryTradeDailyDetail",
            "paramMap": {
              "orgId": "9ce5a4d757f9d6434008b1449d20a641",
              "businessFormatName": this.businessFormatName,
              "shopName": this.shopName,
              "startDate": this.dateValue,
              "endDate": this.dateValue
            }
        },
        "exportFileName": exportFileName
      }
      const res = await this.$http.postdown(this.$api.queryExport, en)     
      // console.log('Export', res)
      if (res.status === 200) {
        this.$fileDownload(res.data, `${exportFileName}.xlsx`)
      } else {
        this.$message.error(res.statusText)
      }
    },
    // 图表数据
    getEchartData (list) {
      // 获取前10条数据
      var allData = list.slice(0,10)
      var shopNames = allData.map(c => c.shopName)
      var receiptCounts = allData.map(c => c.receiptCount)
      var receiptAmounts = allData.map(c => c.receiptAmount)
      var saleAmounts = allData.map(c => c.saleAmount)      
      var purchaseAmounts = allData.map(c => c.purchaseAmount)
      this.updateEchart(shopNames, receiptCounts, receiptAmounts, saleAmounts, purchaseAmounts)
    },
    // 初始化图表
    initEchart () {
      // this.chartInstance = markRaw(this.$echarts.init(this.$refs.chart_ref))
      this.chartInstance = this.$echarts.init(this.$refs.echart_ref)
      const initOption = {
        // title: {
        //   text: formatDate(this.dateValue, 'yyyy年MM月dd日') + '交易日报统计'
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          top: 100,        
          bottom: 80
        },
        // toolbox: {
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ['line', 'bar'] },
        //     restore: { show: true },
        //     saveAsImage: { show: true }
        //   }
        // },
        legend: {
          top: 35,
          data: ['采购金额', '销售金额', '收款金额', '收款笔数' ]
        },
        xAxis: [
          {
            type: 'category',
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: { interval: 0, rotate: 20 }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '收款金额（元）',
            // min: 0,
            // max: 25,
            // interval: 10
          },
          {
            type: 'value',
            name: '收款笔数',
            // min: 0,
            // max: 25,
            // interval: 20,
            // axisLabel: {
            //   formatter: '{value} ml'
            // }
          }
        ],
        series: [
          {
            name: '采购金额',
            type: 'bar',  
            barGap: 0,          
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元'
              }
            }
          },
          {
            name: '销售金额',
            type: 'bar',            
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元'
              }
            }
          },                
          {
            name: '收款金额',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元'
              }
            }
          },
          {
            name: '收款笔数',
            type: 'line',
            yAxisIndex: 1            
          }
        ]
      }
      this.chartInstance.setOption(initOption)      
    },
    // 更新图表
    updateEchart (shopNames, tradeCounts, tradeAmounts, saleAmounts, purchaseAmounts) {
      const dataOption = {
        title: {
          text: formatDate(this.dateValue, 'yyyy年MM月dd日') + '交易日报统计图'
        },
        xAxis: {
          data: shopNames            
        },
        series: [
          {
            data: purchaseAmounts
          }, 
          {
            data: saleAmounts
          }, 
          {
            data: tradeAmounts
          }, 
          {
            data: tradeCounts
          }
        ]
      }
      this.chartInstance.setOption(dataOption)
    },
    screenAdapter () {
      // 调用图表对象的resize才能产生效果
      this.chartInstance.resize()
    }
  }
}
</script>

<style>
</style>
